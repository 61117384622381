import es from 'dayjs/locale/es';
import en from 'dayjs/locale/en';
import dayjs from 'dayjs';
import { IGetMMYYYY } from 'types/Global';

const DEFAULT_ESP = 'ESP';

export const changeDayjsLanguage = (language: string) => dayjs.locale(language === 'es' ? es : en);

export const getMMYYYY = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  const month = dayjs(date).locale(lang).format('MMMM');
  const year = dayjs(date).locale(lang).format('YYYY');
  return `${month}, ${year}`;
};

export const getDDMMYYYY = ({ date, language = DEFAULT_ESP, spacer = '-' }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format(`DD${spacer}MM${spacer}YYYY`);
};

export const getYYYYMMDD = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format('YYYY-MM-DD');
};

export const getCompleteInfo = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format('YYYY-MM-DD HH:mm');
};
