import i18next from 'i18next';

i18next.addResources('es', 'ShipmentDetail', {
  title: 'Tracking',
  home: 'Inicio',
  trackingList: 'Listado de tracking',
  back: 'Volver',
  shipmentDetail: 'Detalle embarque',
  inProcess: 'En preparación',
  inTransit: 'En tránsito',
  departure: 'Zarpe',
  transshipmentPort: 'Transbordo',
  portOfDestination: 'Puerto de destino',
  containerDischarge: 'Descarga de nave',
  delivery: 'Entregado',
  emptyEquipmentReturned: 'Devolución de cont.',
  completed: 'Completado',
  shipmentMilestones: 'Hitos de embarque',
  arrivalDate: "Arribo",
  departureDate: "Salida"
});

i18next.addResources('en', 'ShipmentDetail', {
  title: 'Tracking',
  home: 'Home',
  trackingList: 'Tracking List',
  back: 'Back',
  shipmentDetail: 'Shipment detail',
  inProcess: 'In process',
  inTransit: 'In transit',
  departure: 'Departure',
  transshipmentPort: 'Transsipment port',
  portOfDestination: ' Port of destination',
  containerDischarge: 'Container discharge',
  delivery: 'Delivery',
  emptyEquipmentReturned: 'Empty equipment returned',
  completed: 'Completed',
  shipmentMilestones: 'Shipment milestones',
  arrivalDate: "Arrival",
  departureDate: "Departure"
});
