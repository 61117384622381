// Important: If you add a path of application should start with /orders | /users | /activity_center | /account_settings ... (items of Sidebar)
// For example /users/create is a screen that is into Users item of Sidebar

export default {
  accountSettings: '/account_settings',
  activityCenter: '/activity_center',
  admin: '/admin',
  adminCSAT: '/admin/csat',
  adminCSATbyId: '/admin/csat/:id',
  adminCSATCreate: '/admin/csat/create',
  adminInformativeModal: '/admin/informative_modal',
  adminInformativeModalById: '/admin/informative_modal/:id',
  adminInformativeModalCreate: '/admin/informative_modal/create',
  articleDetail: '/article/:id',
  articles: '/articles',
  certificates: '/certificates',
  createOrder: '/orders/create',
  createUser: '/users/create',
  creditStatus: '/creditStatus',
  creditStatusDetail: '/creditStatus/:id',
  cycles: '/cycles',
  documents: '/documents',
  documentsList: '/documents/:id',
  documentsView: '/documents/view',
  editProductionCycle: '/cycles/edit',
  editUser: '/users/edit/:id',
  helpCenter: '/helpCenter',
  home: '/',
  homeScreen: '/home',
  login: '/login',
  orderDetail: '/orders/detail/:id',
  orders: '/orders',
  packingList: '/orders/:id/packing_list/:shipmentNum',
  qualityCertificates: '/certificates/:id/item/:itemNum/unit/:unitCode',
  qualityCertificatesAVG: '/certificates/:id/item/:itemNum',
  registration: '/registration',
  stockControl: '/stockControl',
  tracking: '/tracking',
  trackingDetail: '/tracking/:id/shipment/:shipmentId/customer/:customerId',
  users: '/users',
  viewItemCertificate: '/orders/detail/:id?view=certificate&itemNum=:itemNum'
};
